import React from 'react';
import { Link } from 'react-router-dom';

export const SelectedClient = () => {
  return (
    <div className="temporary-page">
        <img className="oven" src="/assets/photo/oven.jpg" alt="oven" />
        <div className="cooking-text">this page is still cooking...</div>
        <br />
        <br />
        <Link to="/" className="back-btn">go back</Link>
    </div>
  )
}
