import React from "react";
import "./styles/App.scss";
import { Switch, Route } from "react-router-dom";
import { Landing } from "./components/Landing";
import { About } from "./components/About/About";
import { Clients } from "./components/Clients/Clients";
import { Contact } from "./components/Contact/Contact";
import { SelectedClient } from "./components/Clients/SelectedClients/SelectedClient";

function App() {
  return (
    <div className="App">
      <div className="box">
        <div className="box-inner">
          <Switch>
            <Route exact path="/">
              <Landing />
              <About />
              <Clients />
              <Contact />
            </Route>
            <Route exact path="/client">
              <SelectedClient />
            </Route>
            {/* <Route exact path="/work">
          <Work />
        </Route>
        <Route exact path="/clients">
          <Clients />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route> */}
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
