import React from "react";
import { Link } from "react-router-dom"

export const Clients = () => {
  return (
    <div className="clients-container">
      <div id="clients-header" className="header">
        - clients -
      </div>
      <div className="clients-list">
        <Link to="client" className="client-list-item">
          <div className="client-title">Athletica Magazine | Louis Vuitton</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Bernard James</div>
          <div className="client-description">
            director, director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Billionaire Girls Club</div>
          <div className="client-description">
            director of photography & video editor
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Brandon Blackwood</div>
          <div className="client-description">
            director of photography & video editor
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Camilla and Marc</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">CFDA Vogue</div>
          <div className="client-description">video editor & sound design</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Condé Nast | Vogue Italia</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Condé Nast | Vogue France</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Crack Magazine | Teezo Touchdown</div>
          <div className="client-description">
            producer, director, director of photography, & video editor
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">David Webb</div>
          <div className="client-description">
            director of photography & video editor
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Dazed Korea | Balenciaga</div>
          <div className="client-description">video editor</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Face Magazine</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Fleur Du Mal </div>
          <div className="client-description">
            director, director of photography, video editor, sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Givenchy</div>
          <div className="client-description">video editor & sound design</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Guerlian</div>
          <div className="client-description">director of photography</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Jean Paul Gaultier</div>
          <div className="client-description">director of photography</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Kenzo</div>
          <div className="client-description">video editor & sound design</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Lanvin</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Le Père</div>
          <div className="client-description">
            producer, director, director of photography, video editor, & sound
            design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Leset</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Juventus</div>
          <div className="client-description">
            producer, director, director of photography, video editor, & sound
            design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Marc Jacob’s | Heavn</div>
          <div className="client-description">
            director of photography, video editor, sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Melitta Baumeister</div>
          <div className="client-description">
            director of photography, video editor, sound design 
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Mejuri</div>
          <div className="client-description">
            director, director of photography, video editor, sound design 
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Pyer Moss</div>
          <div className="client-description">
            producer, director, director of photography, video editor, & sound
            design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Rabanne</div>
          <div className="client-description">video editor</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Schön Magazine</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Theory</div>
          <div className="client-description">
            director, director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Valentino</div>
          <div className="client-description">video editor</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Victoria Beckham Beauty</div>
          <div className="client-description">
            director of photography, video editor, & sound design
          </div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Vogue Japan</div>
          <div className="client-description">video editor</div>
        </Link>
        <Link to="client" className="client-list-item">
          <div className="client-title">Wonderland Magazine</div>
          <div className="client-description">
            director, director of photography, & video editor
          </div>
        </Link>
      </div>
    </div>
  );
};
